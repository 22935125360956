<template>

  <q-intersection
    once
    transition="scale">

    <div class="block-about-counter">

      <div class="text-primary">

        {{ number }}

      </div>

      <div
        v-html="text"
        class="text-secondary"/>

    </div>

  </q-intersection>

</template>

<script>
export default {
  name: 'block-about-counter',
  props: {
    number: {
      type: [String, Number],
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
  @import "src/styles/quasar.variables.scss";

  .block-about-counter {
    width: 100%;
    height: 100%;
    padding: 30px;
    display: flex;
    position: relative;
    text-align: center;
    border-radius: 16px;
    flex-direction: column;
    background-color: #fff;
    justify-content: space-between;

    @media (max-width: $breakpoint-lg) {
      padding: 24px;
    }

    &:before {
      content: "";
      left: 10px;
      right: 10px;
      bottom: 15px;
      z-index: -1;
      height: 26px;
      opacity: .40;
      filter: blur(45px);
      position: absolute;
      border-radius: 16px;
      background: linear-gradient(.37turn, #4388FF, #316BFF);
    }

    .text-primary {
      font-size: 32px;
      font-weight: 700;
    }

    .text-secondary {

      @media (max-width: $breakpoint-lg) {
        font-size: 12px;
      }
    }
  }

</style>
