import Vue from 'vue';
import './styles/quasar.scss';
import Quasar from 'quasar/src/vue-plugin.js';import Meta from 'quasar/src/plugins/Meta.js';;
import lang from 'quasar/lang/ru';
import '@quasar/extras/eva-icons/eva-icons.css';
import iconSet from 'quasar/icon-set/material-icons';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';

Vue.use(Quasar, {
  lang,
  iconSet,
  config: {
    notify: {
      position: 'top',
    },
    loadingBar: {
      color: 'accent',
    },
  },
  plugins: [
    Meta,
  ],
});
