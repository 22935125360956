import { render, staticRenderFns } from "./block-faq.vue?vue&type=template&id=3e7dc131&"
import script from "./block-faq.vue?vue&type=script&lang=js&"
export * from "./block-faq.vue?vue&type=script&lang=js&"
import style0 from "./block-faq.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QIntersection from 'quasar/src/components/intersection/QIntersection.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QIntersection,QExpansionItem,QItem,QIcon});
