<template>

  <div class="block-about-bullet">

    <div class="block-about-bullet__icon flex items-center justify-center">

      <q-icon
        v-if="icon"
        size="26px"
        :name="icon"/>

    </div>

    <div class="block-about-bullet__text text-secondary flex items-center">

      {{ text }}

    </div>

  </div>

</template>

<script>
export default {
  name: 'block-about-bullet',
  components: {},
  props: {
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
  @import "src/styles/quasar.variables.scss";

  .block-about-bullet {
    z-index: 1;
    width: 100%;
    height: 50px;
    max-height: 50px;
    display: grid;
    grid-gap: 25px;
    grid-template-columns: 50px 1fr;

    @media (max-width: $breakpoint-lg) {
      grid-gap: 12px;
    }

    &__icon {
      width: 50px;
      height: 50px;
      position: relative;
      border-radius: 16px;
      background-color: #fff;

      &:before {
        content: "";
        left: 10px;
        right: 10px;
        bottom: 3px;
        width: 30px;
        z-index: -1;
        height: 21px;
        opacity: .65;
        filter: blur(22px);
        position: absolute;
        border-radius: 16px;
        background: linear-gradient(.37turn, #4388FF, #316BFF);
      }
    }

    &__text {
      font-size: 14px;
    }
  }

</style>
