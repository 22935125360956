export default function createState({
  extension = {},
} = {}) {
  // Return State
  return {
    item: null,
    items: [],
    loading: false,
    tempItem: null,
    itemsSelectable: [],

    pagination: {
      page: 1,
      total: 0,
      limit: 10,
      descending: false,
      sortBy: 'createdAt',
    },

    // Extension
    ...extension,
  };
}
