import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/routes';

Vue.use(VueRouter);

const router = new VueRouter({
  scrollBehavior: (to) => ((to.hash)
    ? window.scrollTo({ top: document.querySelector(to.hash).offsetTop, behavior: 'smooth' })
    : ({ x: 0, y: 0 })),

  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
