<template>

  <q-page class="layout-main">

    <layout-header :menu-items="menu"/>

    <!-- Block Main -->
    <layout-row>

      <block-main/>

    </layout-row>

    <!-- Block About -->
    <layout-row
      secondary>

      <block-about/>

    </layout-row>

    <!-- Block Properties -->
    <layout-row
      pt="175px"
      pb="100px">

      <block-properties/>

    </layout-row>

    <!-- Block Templates -->
    <layout-row
      secondary
      pt="100px"
      pb="100px">

      <block-templates/>

    </layout-row>

    <!-- Block Prices -->
    <layout-row
      pt="100px"
      pb="100px">

      <block-prices/>

    </layout-row>

    <!-- Block FAQ -->
    <layout-row
      secondary
      pt="100px"
      pb="100px">

      <block-faq :items="faqItems"/>

    </layout-row>

    <!-- Block CTA -->
    <layout-row
      pt="100px"
      pb="100px">

      <block-cta/>

    </layout-row>

    <layout-footer/>

  </q-page>

</template>

<script>
import BlockProperties from '@/components/blocks/block-properties';
import BlockTemplates from '@/components/blocks/block-templates';
import LayoutHeader from '@/components/layout/layout-header';
import LayoutFooter from '@/components/layout/layout-footer';
import BlockPrices from '@/components/blocks/block-pricing';
import BlockAbout from '@/components/blocks/block-about';
import LayoutRow from '@/components/layout/layout-row';
import BlockMain from '@/components/blocks/block-main';
import BlockFaq from '@/components/blocks/block-faq';
import BlockCta from '@/components/blocks/block-cta';

export default {
  name: 'layout-main',
  components: {
    BlockCta,
    BlockFaq,
    BlockMain,
    LayoutRow,
    BlockAbout,
    BlockPrices,
    LayoutFooter,
    LayoutHeader,
    BlockTemplates,
    BlockProperties,
  },
  data() {
    return {
      menu: [
        { name: 'О сервисе', to: '#about' },
        { name: 'Возможности', to: '#properties' },
        { name: 'Шаблоны', to: '#templates' },
        { name: 'Тарифы', to: '#pricing' },
        { name: 'FAQ', to: '#faq' },
      ],
      faqItems: [
        {
          label: 'Что я получу после регистрации?',
          text: 'Мы объединили современные технологии с привычными инструментами, чтобы увеличить '
            + 'вашу конверсию с обычных визиток и страниц в инстаграм, предлагая вашим клиентам '
            + 'удобный способ связаться с вами.',
        },
        {
          label: 'Как происходит оплата?',
          text: 'Мы объединили современные технологии с привычными инструментами, чтобы увеличить '
            + 'вашу конверсию с обычных визиток и страниц в инстаграм, предлагая вашим клиентам '
            + 'удобный способ связаться с вами.',
        },
        {
          label: 'Какие возможности включает редактор?',
          text: 'Мы объединили современные технологии с привычными инструментами, чтобы увеличить '
            + 'вашу конверсию с обычных визиток и страниц в инстаграм, предлагая вашим клиентам '
            + 'удобный способ связаться с вами.',
        },
        {
          label: 'Какой срок жизни онлайн-визитки?',
          text: 'Мы объединили современные технологии с привычными инструментами, чтобы увеличить '
            + 'вашу конверсию с обычных визиток и страниц в инстаграм, предлагая вашим клиентам '
            + 'удобный способ связаться с вами.',
        },
        {
          label: 'Сколько визиток можно сделать на одном аккаунте?',
          text: 'Мы объединили современные технологии с привычными инструментами, чтобы увеличить '
            + 'вашу конверсию с обычных визиток и страниц в инстаграм, предлагая вашим клиентам '
            + 'удобный способ связаться с вами.',
        },
      ],
    };
  },
  created() {
    this.$q.screen.setSizes({
      xs: 300,
      sm: 500,
      md: 900,
      lg: 1200,
    });

    const icons = {
      'app:editor': 'img:/assets/icons/editor.svg',
      'app:stat': 'img:/assets/icons/stat.svg',
      'app:template': 'img:/assets/icons/templates.svg',
      'app:widget': 'img:/assets/icons/widgets.svg',
    };

    this.$q.iconMapFn = (iconName) => {
      const icon = icons[iconName];
      if (icon !== undefined) {
        return { icon };
      }
    };
  },
};
</script>

<style lang="scss">
  @import "src/styles/quasar.variables.scss";

  body {

    h1 {
      margin: 0 auto;
      font-size: 46px;
      font-weight: 700;
      line-height: 54px;

      @media (max-width: $breakpoint-lg) {
        font-size: 34px;
        line-height: 45px;
      }

      @media (max-width: $breakpoint-sm) {
        font-size: 29px;
        line-height: 37px;
      }
    }

    h2 {
      margin: 0 auto;
      font-size: 36px;
      font-weight: 700;
      line-height: 44px;

      @media (max-width: $breakpoint-lg) {
        font-size: 28px;
        line-height: 35px;
      }
    }

    .q-page-sticky {
      z-index: 1;
    }

    .q-layout {
      overflow: hidden;
    }

    .q-btn {
      text-transform: none;
      font-size: 16px;
      padding: 0 22px;
      line-height: 60px;

      &__wrapper {
        padding: 0 28px;
      }

      &--rectangle {
        border-radius: 12px;
      }
    }

    .button-mobile {
      padding: 0;
    }

    .button-primary {
      color: #FFFFFF;
      z-index: 0;
      background: linear-gradient(.57turn, #4388FF, #316BFF);

      &:before {
        content: "";
        top: 30px;
        left: 15px;
        right: 15px;
        bottom: 4px;
        opacity: .7;
        z-index: -1;
        filter: blur(35px);
        position: absolute;
        border-radius: 16px;
        background: linear-gradient(.57turn, #4388FF, #316BFF);
      }
    }

    .button-secondary {

      .q-focus-helper {
        opacity: 0!important;
      }
    }
  }

</style>
