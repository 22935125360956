import map from 'lodash/map';
import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';

export default function updateItems({
  name,
  model,
  client,
  onError,
  onSuccess,
}) {
  return async (context, request = {}) => {
    const actionArgument = {
      name: `update${name[0].toUpperCase()}${name.slice(1)}`,
      args: { ...request.args },
      fields: ['id', 'name'],
    };

    if (request.uid) actionArgument.uid = request.uid;
    if (request.fields) actionArgument.fields = [...actionArgument.fields, ...request.fields];

    let result;
    let items = [];

    try {
      items = await client.mutation(actionArgument, context);
      if (result) {
        items = map(
          result,
          (item) => {
            const theModel = cloneDeep(model);
            return assign(theModel, item);
          },
        );
      }
    } catch (error) {
      onError(error);
    }

    context.commit('UPDATE_ITEMS', items);
    onSuccess(items);
    return items;
  };
}
