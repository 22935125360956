<template>

  <div class="layout-error fixed-center text-center">

    <p
      style="font-size: 240px"
      class="text-faded text-white">

      Извините,

      <template v-if="code === '404'">
        такая страница не существует
      </template>

      <template v-else>
        произошла ошибка
      </template>

      <strong style="font-size: 60px"><br>{{ code }}</strong>

    </p>

  </div>

</template>

<script>
export default {
  name: 'layout-error',
  props: {
    code: {
      type: String,
      default: '404',
    },
  },
};
</script>

<style lang="scss">

</style>
