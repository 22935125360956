<template>

  <div
    id="templates"
    class="block-templates">

    <q-intersection
      once
      transition="scale">

      <h1
        class="text-center">
        Начни с <span class="text-primary">готового шаблона</span>
      </h1>

    </q-intersection>

  </div>

</template>

<script>
export default {
  name: 'block-templates',
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">

</style>
