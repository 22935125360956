<template>

  <q-page-sticky
    expand
    position="top"
    class="layout-header"
    :style="style">

    <q-toolbar>

      <q-img
        width="127px"
        height="25px"
        class="q-mr-xl"
        src="/assets/img/logo.svg"/>

      <template v-if="$q.screen.gt.md">

        <q-btn
          flat
          stretch
          :key="i"
          :ripple="false"
          :label="item.name"
          class="navigation-button"
          @click="$scrollTo(item.to)"
          v-for="(item, i) in menuItems"/>

      </template>

      <q-space/>

      <template v-if="$q.screen.gt.md">

        <q-btn
          unelevated
          color="dark"
          @click="toLogin"
          class="button-login">
          Войти
        </q-btn>

      </template>

      <template v-else>

        <q-btn
          flat
          round
          unelevated
          color="dark"
          class="button-mobile"
          @click="menuDisplayed = !menuDisplayed">

          <!-- TODO: Fix animations -->
          <transition name="flip-left">
            <q-icon :name="menuDisplayed ? 'close' : 'menu'"/>
          </transition>

        </q-btn>

      </template>

    </q-toolbar>

    <q-dialog
      maximized
      v-model="menuDisplayed"
      transition-show="slide-left"
      transition-hide="slide-right">

      <q-card>

        <div class="mobile-navigation full-width full-height">

          <q-btn
            flat
            stretch
            :key="i"
            :ripple="false"
            :label="item.name"
            @click="goTo(item.to)"
            v-for="(item, i) in menuItems"
            class="mobile-navigation__button"/>

          <q-btn
            unelevated
            color="dark"
            @click="toLogin"
            class="button-login q-mt-xl">
            Войти
          </q-btn>

        </div>

      </q-card>

    </q-dialog>

  </q-page-sticky>

</template>

<script>
export default {
  name: 'layout-header',
  props: {
    menuItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      style: '',
      menuDisplayed: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', () => {
      let { scrollY } = window;
      if (scrollY > 300) scrollY = 300;

      const percentage = scrollY / 3;
      const opacity = percentage / 100;

      let height = 120 - (scrollY / 10);

      if (this.$q.screen.lt.lg) height -= 30;

      this.style = {
        height: `${height}px`,
        backgroundColor: `rgba(255, 255, 255, ${opacity})`,
      };
    });
  },
  methods: {
    goTo(elementId) {
      this.menuDisplayed = false;
      this.$nextTick(() => {
        this.$scrollTo(elementId);
      });
    },
    toLogin() {
      window.location.href = 'https://my.bicard.pro';
    },
  },
};
</script>

<style lang="scss">
  @import "src/styles/quasar.variables.scss";

  .layout-header {
    width: 100%;
    height: 120px;
    padding: 0 35px;
    z-index: 10000 !important;
    transition: all 3ms ease-in-out;
    background-color: rgba(255, 255, 255, 0);

    & .scrolled {
      height: 90px;
      background-color: rgba(255, 255, 255, 1);
    }

    .q-toolbar {
      padding: 0;
      max-width: 1100px;
    }

    .button-login {
      padding: 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 42px;
    }

    .navigation-button {
      padding: 0;

      .q-btn {

        &__wrapper {
          padding: 0 15px;
        }

        &__content {
          font-weight: 500;
          color: $secondary;
        }
      }

      &:hover .q-btn__content {
        color: $primary;
      }

      .q-focus-helper {
        opacity: 0!important;
      }
    }

    @media (max-width: $breakpoint-sm) {
      padding: 0 18px;
      height: 60px !important;
    }
  }

  .mobile-navigation {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &__button {
      padding: 0;

      &.q-btn {
        font-size: 24px;
        font-weight: 600;

        &__wrapper {
          padding: 0 15px;
        }
      }

      &:hover .q-btn__content {
        color: $primary;
      }

      .q-focus-helper {
        opacity: 0!important;
      }
    }
  }

</style>
