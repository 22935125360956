export default function removeItems({
  name,
  client,
  onError,
  onSuccess,
}) {
  return async (context, request = {}) => {
    const actionArgument = {
      name: `remove${name[0].toUpperCase()}${name.slice(1)}`,
      args: { ...request.args },
    };

    if (request.uid) actionArgument.uid = request.uid;
    if (request.fields) actionArgument.fields = [...actionArgument.fields, ...request.fields];

    let result;

    try {
      result = await client.mutation(actionArgument, context);
    } catch (error) {
      onError(error);
    }

    if (result) context.commit('REMOVE_ITEMS', [request.args.id]);
    onSuccess(result);
    return result;
  };
}
