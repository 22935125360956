import map from 'lodash/map';
import each from 'lodash/each';
import isNil from 'lodash/isNil';
import pluralize from 'pluralize';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

function stringifyGqlArg(argument) {
  if (argument === undefined) return '';
  if (argument === null) return 'null';

  if (isArray(argument)) {
    return `[${
      argument
        .map((arg) => stringifyGqlArg(arg))
        .join(',')
    }]`;
  }

  if (isObject(argument)) {
    const value = map(
      argument,
      (arg, key) => {
        if (isNil(arg)) return `${key}: null`;
        return `${key}: ${stringifyGqlArg(arg)}`;
      },
    ).filter((item) => item !== null).join(',');

    return `{${value}}`;
  }

  if (isString(argument) && argument !== '$file') {
    if (!argument.length) return null;
    return JSON.stringify(argument);
  }

  return argument;
}

function stringifyGqlArgs(fields) {
  let result = '';

  if (fields === undefined) return result;

  if (isObject(fields)) {
    result += map(
      fields,
      (arg, key) => {
        if (isNil(arg)) return `${key}: null`;
        return `${key}: ${stringifyGqlArg(arg)}`;
      },
    ).filter((item) => item !== null).join(',');
  }

  if (isString(fields) && fields !== '$file') {
    if (!fields.length) return null; // Cделали отправку null вместо пустой строки
    result += JSON.stringify(fields);
  }

  return `(${result})`;
}

function getFieldsByArray(values) {
  if (values.length === 1) return values;

  const name = values[0];

  values.shift();

  return `${name}{${getFieldsByArray(values)}}`;
}

function getFieldsByString(string) {
  const values = string.split('.');
  if (values.length === 1) return string;

  const name = values[0];

  values.shift();

  return `${name}{${getFieldsByArray(values)}}`;
}

function stringifyGqlFields(fields) {
  let result = '';

  result += '{';
  each(fields, (field) => {
    if (isObject(field)) {
      result += field.name;
      result += stringifyGqlFields(field.fields);
    } else result += `${getFieldsByString(field)} `;
  });
  result += '}';

  return result;
}

export function stringifyGqlQuery(object) {
  if (isArray(object)) return object.map((item) => stringifyGqlQuery(item)).join('');

  let query = isString(object.name) ? object.name : object.name;

  if (!isEmpty(object.args)) query += stringifyGqlArgs(object.args);
  if (!isEmpty(object.fields)) query += stringifyGqlFields(object.fields);

  return query;
}

export function stringifyGqlPaginateQuery(object) {
  let query = `${object.name} `;
  const nameSingle = pluralize(object.name, 1);

  if (!isEmpty(object.args)) query += stringifyGqlArgs(object.args);

  query += ` { data { ... on ${nameSingle[0].toUpperCase()}${nameSingle.slice(1)} `;

  if (!isEmpty(object.fields)) query += stringifyGqlFields(object.fields);

  query += '} pagination { page total limit sortBy hasMore descending }}';

  return query;
}
