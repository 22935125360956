export default function fetchItems({
  name,
  client,
}) {
  return async (context, request = {}) => {
    const { state } = context;

    const actionArgument = {
      name,
      args: {
        ...request.args || [],
        pagination: {
          page: state.pagination.page,
          limit: state.pagination.limit,
          sortBy: state.pagination.sortBy,
          descending: state.pagination.descending,
        },
      },
      fields: ['id'],
    };

    if (request.fields) actionArgument.fields = request.fields;

    const { data, pagination } = await client.paginateQuery(actionArgument);

    context.commit('SET_ITEMS', data);
    context.commit('SET_PAGINATION', pagination);

    return data;
  };
}
