import { render, staticRenderFns } from "./layout-footer.vue?vue&type=template&id=2761ff67&"
import script from "./layout-footer.vue?vue&type=script&lang=js&"
export * from "./layout-footer.vue?vue&type=script&lang=js&"
import style0 from "./layout-footer.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QImg from 'quasar/src/components/img/QImg.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QImg,QList,QItem,QItemSection});
