export default function createGetters({
  extension = {},
} = {}) {
  // Return getters.
  return {
    value(state) {
      return state.tempItem;
    },

    // Extension.
    ...extension,
  };
}
