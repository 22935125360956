<template>

  <div
    id="about"
    class="block-about">

    <!-- Bullets -->
    <div class="block-about__bullets">

      <block-about-bullet
        icon="app:template"
        text="Готовые шаблоны для быстрого старта"/>

      <block-about-bullet
        icon="app:stat"
        text="Статистика просмотров и конверсии"/>

      <block-about-bullet
        icon="app:editor"
        text="Редактор визиток для печати"/>

      <block-about-bullet
        icon="app:widget"
        text="Более 20 виджетов для вашей страницы"/>

    </div>

    <!-- Description -->
    <div class="block-about__description">

      <div class="block-about__description__wrapper">

        <div class="block-about__description__image">

        </div>

        <div class="block-about__description__content">

          <h1>
            Для чего нужна <br/>
            <span class="text-primary">онлайн-визитка?</span>
          </h1>

          <div class="text-secondary">
            Создай собственный дизайн или выбери из готовых шаблонов.<br/><br/>

            Удобный редактор позволит быстро разобраться и реализовать свои уникальные идеи
            оформления без программистов и дизайнеров. А коллекция из более чем 20 виджетов вам
            в этом поможет.
          </div>

          <q-btn
            type="a"
            unelevated
            class="button-primary"
            href="https://my.bicard.pro/signup">
            Попробовать бесплатно
          </q-btn>

        </div>

      </div>

    </div>

    <!-- Wrapper -->
    <div class="block-about__counters">

      <div class="block-about__counters__wrapper">

        <block-about-counter
          number="6 175"
          :text="'онлайн-визиток <br/>создано в bicard'"/>

        <block-about-counter
          number="40+"
          :text="'готовых шаблонов <br/>для разных ниш'"/>

        <block-about-counter
          number="5 дней"
          :text="'бесплатного доступа <br/>к сервису'"/>

        <block-about-counter
          number="9,8 млн"
          :text="'заявок получили <br/>наши клиенты'"/>

      </div>

    </div>

  </div>

</template>

<script>
import BlockAboutCounter from '@/components/blocks/block-about-counter';
import BlockAboutBullet from '@/components/blocks/block-about-bullet';

export default {
  name: 'block-about',
  components: {
    BlockAboutBullet,
    BlockAboutCounter,
  },
};
</script>

<style lang="scss">
  @import "src/styles/quasar.variables.scss";

  .block-about {
    position: relative;

    h1 {
      margin: 0;

      @media (max-width: $breakpoint-md) {
        margin: 0 auto;
      }
    }

    &__bullets {
      height: 150px;
      display: grid;
      grid-gap: 50px;
      padding: 50px 0;
      grid-template-columns: repeat(4, 1fr);

      @media (max-width: $breakpoint-lg) {
        grid-gap: 18px;
      }

      @media (max-width: $breakpoint-md) {
        height: 180px;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: $breakpoint-sm) {
        height: 260px;
        padding: 25px 0;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
      }
    }

    &__description {
      padding: 50px 0;

      &__wrapper {
        display: grid;
        grid-gap: 30px;
        grid-template-columns: 1fr 1fr;

        @media (max-width: $breakpoint-md) {
          grid-template-columns: 1fr;
          grid-template-rows: 1fr 1fr;
        }

        @media (max-width: $breakpoint-sm) {
          grid-template-rows: 250px 1fr;
        }
      }

      &__image {
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 16px;
        background-color: #fff;
      }

      &__content {
        height: 378px;
        display: flex;
        line-height: 28px;
        padding-left: 30px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        @media (max-width: $breakpoint-lg) {
          font-size: 14px;
          line-height: 24px;
        }

        @media (max-width: $breakpoint-md) {
          padding: 0;
        }

        @media (max-width: $breakpoint-sm) {
          text-align: center;

          .q-btn {
            margin: 0 auto;
          }
        }
      }
    }

    &__counters {
      height: 125px;
      position: relative;

      @media (max-width: $breakpoint-md) {
        height: auto;
        padding-bottom: 50px;
      }

      @media (max-width: $breakpoint-sm) {
        padding-bottom: 25px;
      }

      &__wrapper {
        top: 50px;
        width: 100%;
        height: 150px;
        display: grid;
        grid-gap: 30px;
        position: absolute;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        @media (max-width: $breakpoint-md) {
          top: 0;
          height: auto;
          position: relative;
          grid-template-rows: 1fr 1fr;
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }

</style>
