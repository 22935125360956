<template>

  <div
    class="page-block"
    @click="$emit('click')">

    <page-block-button
      :design="design"
      :icon="value.icon"
      :title="value.title"
      :bg-color="value.bgColor"
      :subtitle="value.subtitle"
      :text-color="value.textColor"
      :icon-color="value.iconColor"
      :icon-bg-color="value.iconBgColor"
      v-if="value.type === 'button' && !disabled"
      :image-link="value.image ? getFileUrl(value.image) : ''"/>

    <page-block-button
      :design="design"
      :title="value.title"
      :bg-color="value.bgColor"
      :subtitle="value.subtitle"
      :text-color="value.textColor"
      v-if="value.type === 'social' && !disabled"
      :image-link="'https://fs.bicard.pro/icon/' + value.preset + '.svg'"/>

    <page-block-header
      :text="value.text"
      :title="value.title"
      :text-color="value.textColor"
      v-if="value.type === 'header'"
      :image-link="value.image ? getFileUrl(value.image) : ''"/>

  </div>

</template>

<script>
import PageBlockButton from './blocks/page-block-button';
import PageBlockHeader from './blocks/page-block-header';
import Mixins from '../../mixins';

export default {
  name: 'page-block',
  components: {
    PageBlockHeader,
    PageBlockButton,
  },
  mixins: [Mixins],
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    design: {
      type: Object,
      default: () => ({}),
    },
    first: {
      type: Boolean,
      default: false,
    },
    last: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
  @import "src/styles/quasar.variables.scss";

  .page-block {
    display: block;
    cursor: pointer;
    position: relative;

    &.disabled {
      pointer-events: none;
    }
  }

</style>
