import createState from './state';
import createGetters from './getters';
import createActions from './actions';
import createMutations from './mutations';

// Create module function
export default function createModule({
  name,
  model,
  client,
  state,
  getters,
  actions,
  mutations,
  onError,
  onSuccess,
} = {}) {
  const mergedState = createState({
    extension: state,
  });

  const mergedGetters = createGetters({
    extension: getters,
  });

  const mergedMutations = createMutations({
    initialState: mergedState,
    extension: mutations,
  });

  const mergedActions = createActions({
    name,
    model,
    client,
    request: {},
    extension: actions,
    onError,
    onSuccess,
  });

  return {
    state: mergedState,
    getters: mergedGetters,
    actions: mergedActions,
    mutations: mergedMutations,
  };
}
