<template>

  <div class="layout-page">

    <page-viewer :value="item"/>

  </div>

</template>

<script>
import { mapState } from 'vuex';
import { blockFields } from '@/utils';
import PageViewer from '@/components/page/page-viewer';

export default {
  name: 'layout-page',
  components: {
    PageViewer,
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      title: 'bicard.pro',
      description: '',
    };
  },
  meta() {
    return {
      title: this.title,
      meta: {
        description: { name: 'description', content: this.description },
      },
    };
  },
  computed: {
    ...mapState({
      item: (state) => state.pages.item,
    }),
  },
  created() {
    this.$store.commit('pages/RESET_ITEM');
    this.$store.dispatch('pages/FETCH_ITEM', {
      args: { slug: this.slug },
      fields: [
        'id',
        'slug',
        'name',
        'description',
        {
          name: 'image',
          fields: ['id', 'server', 'path'],
        },
        {
          name: 'owner',
          fields: ['subscribeTo', 'subscribeType'],
        },
        {
          name: 'design',
          fields: [
            'fontFamily',
            'pageBg',
            'headerBg',
            'pageTextColor',
            'headerTextColor',
            'shadowSize',
            'borderRadius',
            'shadowOpacity',
            'shadowColor',
            'bgColor',
            'textColor',
            'iconColor',
            'iconBgColor',
          ],
        },
        {
          name: 'items',
          fields: blockFields(),
        },
      ],
    }).then((item) => {
      this.title = item.name;
      this.description = item.description;
    });
  },
  mounted() {
    // Statistic event
    this.$store.dispatch('events/EMIT', {
      type: 'view',
      page: { slug: this.slug },
    });
  },
};
</script>
