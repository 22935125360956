import fetchItem from './fetch-item';
import appendItem from './append-item';
import updateItem from './update-item';
import removeItem from './remove-item';
import fetchItems from './fetch-items';
import updateItems from './update-items';
import removeItems from './remove-items';
import fetchItemsSelectable from './fetch-items-selectable';

export default function createActions({
  name,
  model,
  client,
  extension = {},
  onError,
  onSuccess,
} = {}) {
  // Create extension
  let extensions = typeof extension === 'function'
    // eslint-disable-next-line prefer-rest-params
    ? extension(...arguments)
    : extension;

  extensions = typeof extensions === 'object'
    ? extensions
    : {};

  // Options
  const options = {
    name,
    model,
    client,
    onError,
    onSuccess,
  };

  return {
    FETCH_ITEM: fetchItem(options),
    APPEND_ITEM: appendItem(options),
    UPDATE_ITEM: updateItem(options),
    REMOVE_ITEM: removeItem(options),
    FETCH_ITEMS: fetchItems(options),
    UPDATE_ITEMS: updateItems(options),
    REMOVE_ITEMS: removeItems(options),
    FETCH_ITEMS_SELECTABLE: fetchItemsSelectable(options),

    // Extension
    ...extensions,
  };
}
