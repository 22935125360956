import pluralize from 'pluralize';

export default function removeItem({
  name,
  client,
}) {
  return async (context, request = {}) => {
    const singleName = pluralize(name, 1);

    const actionArgument = {
      name: `remove${singleName[0].toUpperCase()}${singleName.slice(1)}`,
      args: { ...request.args || {} },
    };

    if (request.uid) actionArgument.uid = request.uid;
    if (request.fields) actionArgument.fields = request.fields;

    const response = await client.mutation(actionArgument, context);
    if (response) context.commit('REMOVE_ITEMS', [request.args.id]);

    return response;
  };
}
