<template>

  <div class="layout-footer">

    <div class="layout-footer__wrapper">

      <div class="layout-footer__logo">

        <q-img
          width="127px"
          height="25px"
          src="/assets/img/logo-white.svg"/>

      </div>

      <div class="layout-footer__description text-white">
        Мы объединили современные технологии с привычными
        инструментами, чтобы увеличить вашу конверсию с обычных
        визиток и страниц в инстаграм, предлагая вашим клиентам
        удобный способ связаться с вами.
      </div>

      <q-list dense class="layout-footer__navigation-one">

        <q-item class="text-bold text-white">
          Основное
        </q-item>

        <q-item
          clickable
          class="text-white"
          @click="$scrollTo('#about')">
          <q-item-section>О сервисе</q-item-section>
        </q-item>

        <q-item
          clickable
          class="text-white"
          @click="$scrollTo('#properties')">
          <q-item-section>Возможности</q-item-section>
        </q-item>

        <q-item
          clickable
          class="text-white"
          @click="$scrollTo('#templates')">
          <q-item-section>Шаблоны</q-item-section>
        </q-item>

        <q-item
          clickable
          class="text-white"
          @click="$scrollTo('#pricing')">
          <q-item-section>Тарифы</q-item-section>
        </q-item>

      </q-list>

      <q-list dense class="layout-footer__navigation-two">

        <q-item class="text-bold text-white">
          Остались вопросы?
        </q-item>

        <q-item
          clickable
          class="text-white"
          @click="$scrollTo('#faq')">
          <q-item-section>Вопрос-ответ</q-item-section>
        </q-item>

        <q-item
          clickable
          class="text-white"
          @click="mailto">
          <q-item-section>Связаться с нами</q-item-section>
        </q-item>

        <q-item
          clickable
          class="text-white"
          @click="() => {}">
          <q-item-section>Политика конфиденциальности</q-item-section>
        </q-item>

      </q-list>

      <div class="layout-footer__copy text-white">
        © BiCard, 2021 Все права защищены.
      </div>

      <div class="layout-footer__payments">

        <div/>

        <q-img
          width="52px"
          height="34px"
          src="/assets/icons/visa.svg"/>

        <q-img
          width="52px"
          height="34px"
          src="/assets/icons/mastercard.svg"/>

        <q-img
          width="52px"
          height="34px"
          src="/assets/icons/qiwi.svg"/>

        <q-img
          width="52px"
          height="34px"
          src="/assets/icons/mir.svg"/>

        <q-img
          width="52px"
          height="34px"
          src="/assets/icons/maestro.svg"/>

      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: 'layout-footer',
  methods: {
    mailto() {
      window.location = 'mailto:info@bicard.pro';
    },
  },
};
</script>

<style lang="scss">
  @import "src/styles/quasar.variables.scss";

  .layout-footer {
    width: 100%;
    padding: 0 35px;
    position: relative;
    background: linear-gradient(.37turn, #4388FF, #316BFF);

    &__wrapper {
      display: grid;
      margin: 0 auto;
      max-width: 1100px;
      padding: 60px 0 25px;
      grid-template-rows: repeat(3, auto);
      grid-template-columns: 1fr 1fr 80px 1fr 1fr;

      @media (max-width: $breakpoint-lg) {
        grid-template-columns: 1fr 1fr 30px 1fr 1fr;
      }

      @media (max-width: $breakpoint-md) {
        grid-template-rows: repeat(6, auto);
      }
    }

    &__logo {
      height: 45px;
      display: flex;
      align-items: center;
      grid-area: 1 / 1 / 2 / 3;

      @media (max-width: $breakpoint-md) {
        grid-area: 1 / 1 / 2 / 6;
      }
    }

    &__description {
      min-height: 140px;
      display: flex;
      padding: 22px 0;
      align-items: center;
      grid-area: 2 / 1 / 3 / 3;

      @media (max-width: $breakpoint-md) {
        grid-area: 2 / 1 / 3 / 6;
      }
    }

    &__navigation-one {
      grid-area: 1 / 4 / 3 / 5;

      @media (max-width: $breakpoint-md) {
        grid-area: 3 / 1 / 4 / 3;
      }
    }

    &__navigation-two {
      grid-area: 1 / 5 / 3 / 6;

      @media (max-width: $breakpoint-md) {
        grid-area: 3 / 3 / 4 / 6;
      }
    }

    &__copy {
      display: flex;
      align-items: center;
      grid-area: 3 / 1 / 4 / 3;

      @media (max-width: $breakpoint-md) {
        grid-area: 5 / 1 / 6 / 6;
      }
    }

    &__payments {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 1fr repeat(5, 52px);
      align-items: end;
      grid-area: 3 / 4 / 4 / 6;

      @media (max-width: $breakpoint-md) {
        padding: 22px 10px 22px 0;
        grid-area: 4 / 1 / 5 / 6;
        justify-self: center;
        grid-template-columns: 0 repeat(5, 52px);
      }
    }

    .q-focus-helper {
      display: none;
    }

    .q-item {
      display: flex;
      padding: 0 !important;
      flex-direction: column;
      justify-content: center;

      :hover {
        opacity: .7;
      }
    }
  }

</style>
