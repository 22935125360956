<template>

  <div class="block-cta">

    <div class="block-cta__title">
      Выделяйся! Создай свою <br/>уникальную онлайн-визитку!
    </div>

    <q-btn
      flat
      type="a"
      class="text-primary"
      href="https://my.bicard.pro/signup">
      Попробовать бесплатно
    </q-btn>

    <div class="block-cta__image"/>

  </div>

</template>

<script>
export default {
  name: 'block-cta',
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
  @import "src/styles/quasar.variables.scss";

  .block-cta {
    width: 100%;
    height: 320px;
    display: flex;
    position: relative;
    border-radius: 30px;
    flex-direction: column;
    padding: 70px 100px 60px;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #4388FF;
    background-image: url("/assets/img/block-cta-background.png");
    background-size: cover;

    @media (max-width: $breakpoint-lg) {
      height: 280px;
      padding: 60px 70px 50px;
    }

    @media (max-width: $breakpoint-md) {
      height: 480px;
      display: block;
      padding: 40px 20px;
      text-align: center;
    }

    &:before {
      content: "";
      top: 47px;
      left: 55px;
      right: 55px;
      bottom: 14px;
      z-index: -1;
      opacity: .50;
      filter: blur(50px);
      position: absolute;
      border-radius: 16px;
      background: linear-gradient(.37turn, #4388FF, #316BFF);
    }

    &__title {
      color: #FFFFFF;
      font-size: 36px;
      line-height: 42px;
      font-weight: 700;

      @media (max-width: $breakpoint-lg) {
        font-size: 32px;
        line-height: 38px;
      }

      @media (max-width: $breakpoint-md) {
        font-size: 28px;
        line-height: 34px;
        padding-bottom: 40px;
      }
    }

    .q-btn {
      z-index: 1;
      background-color: #FFFFFF !important;

      &__wrapper {
        padding: 0 20px;
      }
    }

    &__image {
      right: 0;
      bottom: 0;
      width: 666px;
      height: 395px;
      position: absolute;

      background-image: url("/assets/img/block-cta-image.png");
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: contain;

      @media (max-width: $breakpoint-lg) {
        width: 535px;
        height: 317px;
      }

      @media (max-width: $breakpoint-md) {
        width: 100%;
        height: 317px;
      }
    }
  }

</style>
