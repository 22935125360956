import pluralize from 'pluralize';

export default function appendItem({
  name,
  client,
}) {
  return async (context, request = {}) => {
    const singleName = pluralize(name, 1);

    const actionArgument = {
      name: `create${singleName[0].toUpperCase()}${singleName.slice(1)}`,
      args: { ...request.args || {} },
      fields: ['id'],
    };

    if (request.uid) actionArgument.uid = request.uid;
    if (request.fields) actionArgument.fields = [...actionArgument.fields, ...request.fields];

    const item = await client.mutation(actionArgument);
    context.commit('APPEND_ITEM', item);

    return item;
  };
}
