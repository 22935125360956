<template>

  <div
    class="page-block-header"
    :style="{ color: textColor || design.headerTextColor }">

    <q-img
      v-if="imageLink"
      :src="imageLink"
      class="page-block-header__avatar"/>

    <div class="page-block-header__title">
      {{ title }}
    </div>

    <div class="page-block-header__subtitle">
      {{ text }}
    </div>

  </div>

</template>

<script>
export default {
  name: 'page-block-header',
  components: {},
  props: {
    imageLink: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    design: {
      type: Object,
      default: () => ({}),
    },
    textColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {

    };
  },
  methods: {},
};
</script>

<style lang="scss">

  .page-block-header {
    text-align: center;
    padding-bottom: 32px;

    &__avatar {
      border-radius: 50%;
      margin: 0 auto 13px;
      width: 80px !important;
      height: 80px !important;
    }

    &__title {
      font-size: 17px;
      font-weight: 500;
      margin-bottom: 7px;
    }

    &__subtitle {
      opacity: .5;
      font-size: 14px;
    }
  }

</style>
