<template>

  <div
    id="main"
    class="block-main column justify-center">

    <div class="block-main__wrapper">

      <div class="block-main__content">

        <h1 class="block-main__content__title">
          Создай онлайн-визитку<br/>
          и <span class="text-primary">увеличь конверсию</span><br/>
          <span class="text-secondary">в 6 раз за 5 минут</span>
        </h1>

        <div class="block-main__content__subtitle text-secondary q-mb-md">
          Мы объединили современные технологии с привычными
          инструментами, чтобы увеличить вашу конверсию с обычных
          визиток <span class="text-no-wrap">и инстаграм-страниц</span>, предлагая вашим клиентам
          удобный способ связаться с вами.
        </div>

        <div class="block-main__content__buttons">

          <q-btn
            type="a"
            unelevated
            class="button-primary"
            href="https://my.bicard.pro/signup">
            Попробовать бесплатно
          </q-btn>

          <q-btn
            flat
            color="primary"
            :ripple="false"
            @click="$scrollTo('#about')"
            class="button-secondary gt-lg">
            Узнать подробнее
          </q-btn>

        </div>

      </div>

      <div class="block-main__picture">

      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: 'block-main',
  methods: {
    goToRegistration() {
      window.location.href = 'https://my.bicard.pro/signup';
    },
  },
};
</script>

<style lang="scss">
  @import "src/styles/quasar.variables.scss";

  .block-main {
    padding-top: 120px;
    height: calc(100vh - 150px);

    @media (max-width: $breakpoint-lg) {
      height: auto;
    }

    @media (max-width: $breakpoint-sm) {
      padding-top: 60px;
      text-align: center;
    }

    h1 {
      margin: 0;
    }

    &__wrapper {
      width: 100%;
      height: 100%;
      display: grid;
      max-height: 600px;
      padding: 4vh 0 12vh;
      grid-template-columns: 1fr 1fr;

      @media (max-width: $breakpoint-md) {
        padding: 4vh 0 0;
        max-height: unset;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 500px;
      }

      @media (max-width: $breakpoint-sm) {
        grid-template-rows: 1fr 400px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__title .text-secondary {
        font-size: 36px;
        font-weight: 600;

        @media (max-width: $breakpoint-lg) {
          font-size: 23px;
        }
      }

      &__subtitle {
        padding: 24px 0;
        font-size: 16px;
        max-width: 500px;
        line-height: 28px;

        @media (max-width: $breakpoint-lg) {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }

    &__picture {
      position: absolute;
      height: calc(100vh - 150px);
      width: 50%;
      top: 0;
      left: 50%;

      background-image: url("/assets/img/main-image.png");
      background-repeat: no-repeat;
      background-size: auto 110vh;
      background-position-y: calc(-8vh);

      @media (max-width: $breakpoint-lg) {
        background-size: auto 120%;
      }

      @media (max-width: $breakpoint-md) {
        top: auto;
        left: auto;
        width: 100%;
        height: 500px;
        position: relative;
        background-size: auto 120%;
        background-position-y: 30px;
        background-position-x: center;
      }

      @media (max-width: $breakpoint-sm) {
        height: 400px;
      }
    }
  }

</style>
