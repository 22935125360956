// Layouts
import LayoutMain from '../layouts/layout-main.vue';
import LayoutPage from '../layouts/layout-page.vue';
import LayoutError from '../layouts/layout-error.vue';

const routes = [
  {
    path: '/',
    name: 'main',
    component: LayoutMain,
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../layouts/layout-privacy.vue'),
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import(/* webpackChunkName: "agreement" */ '../layouts/layout-agreement.vue'),
  },
  {
    path: '/error/:code',
    name: 'error',
    component: LayoutError,
    props: true,
  },
  {
    path: '/:slug',
    component: LayoutPage,
    props: true,
  },
  {
    path: '*',
    component: LayoutError,
  },
];

export default routes;
