//  Adapted version of lib ClientJS
//
//  Original Author: Jack Spirou
//  Original Data: 5 Nov 2013

import UAParser from 'ua-parser-js';

let browserData; // Global user agent browser object.

// ClientJS constructor which sets the browserData object and returs the client object.
const ClientJS = () => {
  let parser = new UAParser();
  browserData = parser.getResult();
  return this;
};

// ClientJS prototype which contains all methods.
ClientJS.prototype = {

  //
  // MAIN METHODS
  //

  // Get Software Version.  Return a string containing this software version number.
  getSoftwareVersion() {
    let version = '0.1.11';
    return version;
  },

  // Get Browser Data.  Return an object containing browser user agent.
  getBrowserData() {
    return browserData;
  },

  //
  // USER AGENT METHODS
  //

  // Get User Agent.  Return a string containing unparsed user agent.
  getUserAgent() {
    return browserData.ua;
  },

  // Get User Agent Lower Case.  Return a lowercase string containing the user agent.
  getUserAgentLowerCase() {
    return browserData.ua.toLowerCase();
  },

  //
  // BROWSER METHODS
  //

  // Get Browser.  Return a string containing the browser name.
  getBrowser() {
    return browserData.browser.name;
  },

  // Get Browser Version.  Return a string containing the browser version.
  getBrowserVersion() {
    return browserData.browser.version;
  },

  // Get Browser Major Version.  Return a string containing the major browser version.
  getBrowserMajorVersion() {
    return browserData.browser.major;
  },

  // Is IE.  Check if the browser is IE.
  isIE() {
    return (/IE/i.test(browserData.browser.name));
  },

  // Is Chrome.  Check if the browser is Chrome.
  isChrome() {
    return (/Chrome/i.test(browserData.browser.name));
  },

  // Is Firefox.  Check if the browser is Firefox.
  isFirefox() {
    return (/Firefox/i.test(browserData.browser.name));
  },

  // Is Safari.  Check if the browser is Safari.
  isSafari() {
    return (/Safari/i.test(browserData.browser.name));
  },

  // Is Mobile Safari.  Check if the browser is Safari.
  isMobileSafari() {
    return (/Mobile\sSafari/i.test(browserData.browser.name));
  },

  // Is Opera.  Check if the browser is Opera.
  isOpera() {
    return (/Opera/i.test(browserData.browser.name));
  },

  //
  // ENGINE METHODS
  //

  // Get Engine.  Return a string containing the browser engine.
  getEngine() {
    return browserData.engine.name;
  },

  // Get Engine Version.  Return a string containing the browser engine version.
  getEngineVersion() {
    return browserData.engine.version;
  },

  //
  // OS METHODS
  //

  // Get OS.  Return a string containing the OS.
  getOS() {
    return browserData.os.name;
  },

  // Get OS Version.  Return a string containing the OS Version.
  getOSVersion() {
    return browserData.os.version;
  },

  // Is Windows.  Check if the OS is Windows.
  isWindows() {
    return (/Windows/i.test(browserData.os.name));
  },

  // Is Mac.  Check if the OS is Mac.
  isMac() {
    return (/Mac/i.test(browserData.os.name));
  },

  // Is Linux.  Check if the OS is Linux.
  isLinux() {
    return (/Linux/i.test(browserData.os.name));
  },

  // Is Ubuntu.  Check if the OS is Ubuntu.
  isUbuntu() {
    return (/Ubuntu/i.test(browserData.os.name));
  },

  // Is Solaris.  Check if the OS is Solaris.
  isSolaris() {
    return (/Solaris/i.test(browserData.os.name));
  },

  //
  // DEVICE METHODS
  //

  // Get Device.  Return a string containing the device.
  getDevice() {
    return browserData.device.model;
  },

  // Get Device Type.  Return a string containing the device type.
  getDeviceType() {
    return browserData.device.type;
  },

  // Get Device Vendor.  Return a string containing the device vendor.
  getDeviceVendor() {
    return browserData.device.vendor;
  },

  //
  // CPU METHODS
  //

  // Get CPU.  Return a string containing the CPU architecture.
  getCPU() {
    return browserData.cpu.architecture;
  },

  //
  // MOBILE METHODS
  //

  // Is Mobile.  Check if the browser is on a mobile device.
  isMobile() {
    // detectmobilebrowsers.com JavaScript Mobile Detection Script
    let dataString = browserData.ua || navigator.vendor || window.opera;
    return (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(dataString) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(dataString.substr(0, 4)));
  },

  // Is Mobile.  Check if the browser is on an android mobile device.
  isMobileAndroid() {
    if (browserData.ua.match(/Android/i)) {
      return true;
    }
    return false;
  },

  // Is Mobile Opera.  Check if the browser is on an opera mobile device.
  isMobileOpera() {
    if (browserData.ua.match(/Opera Mini/i)) {
      return true;
    }
    return false;
  },

  // Is Mobile Windows.  Check if the browser is on a windows mobile device.
  isMobileWindows() {
    if (browserData.ua.match(/IEMobile/i)) {
      return true;
    }
    return false;
  },

  // Is Mobile BlackBerry.  Check if the browser is on a blackberry mobile device.
  isMobileBlackBerry() {
    if (browserData.ua.match(/BlackBerry/i)) {
      return true;
    }
    return false;
  },

  //
  // MOBILE APPLE METHODS
  //

  // Is Mobile iOS.  Check if the browser is on an Apple iOS device.
  isMobileIOS() {
    if (browserData.ua.match(/iPhone|iPad|iPod/i)) {
      return true;
    }
    return false;
  },

  // Is Iphone.  Check if the browser is on an Apple iPhone.
  isIphone() {
    if (browserData.ua.match(/iPhone/i)) {
      return true;
    }
    return false;
  },

  // Is Ipad.  Check if the browser is on an Apple iPad.
  isIpad() {
    if (browserData.ua.match(/iPad/i)) {
      return true;
    }
    return false;
  },

  // Is Ipod.  Check if the browser is on an Apple iPod.
  isIpod() {
    if (browserData.ua.match(/iPod/i)) {
      return true;
    }
    return false;
  },

  //
  // SCREEN METHODS
  //

  // Get Screen Print.  Return a string containing screen information.
  getScreenPrint() {
    return 'Current Resolution: ' + this.getCurrentResolution() + ', Available Resolution: ' + this.getAvailableResolution() + ', Color Depth: ' + this.getColorDepth() + ', Device XDPI: ' + this.getDeviceXDPI() + ', Device YDPI: ' + this.getDeviceYDPI();
  },

  // Get Color Depth.  Return a string containing the color depth.
  getColorDepth() {
    return window.screen.colorDepth;
  },

  // Get Current Resolution.  Return a string containing the current resolution.
  getCurrentResolution() {
    return window.screen.width + 'x' + window.screen.height;
  },

  // Get Available Resolution.  Return a string containing the available resolution.
  getAvailableResolution() {
    return window.screen.availWidth + 'x' + window.screen.availHeight;
  },

  // Get Device XPDI.  Return a string containing the device XPDI.
  getDeviceXDPI() {
    return window.screen.deviceXDPI;
  },

  // Get Device YDPI.  Return a string containing the device YDPI.
  getDeviceYDPI() {
    return window.screen.deviceYDPI;
  },

  //
  // TIME METHODS
  //

  // Get Time Zone.  Return a string containing the time zone.
  getTimeZone() {
    let rightNow = new Date();
    return String(String(rightNow).split('(')[1]).split(')')[0];
  },

  //
  // LANGUAGE METHODS
  //

  // Get Language.  Return a string containing the user language.
  getLanguage() {
    return navigator.language;
  },

  // Get System Language.  Return a string containing the system language.
  getSystemLanguage() {
    return navigator.systemLanguage;
  },

  //
  // CANVAS METHODS
  //

  // Is Canvas.  Check if the canvas element is enabled.
  isCanvas() {
    // create a canvas element
    let elem = document.createElement('canvas');

    // try/catch for older browsers that don't support the canvas element
    try {
      // check if context and context 2d exists
      return !!(elem.getContext && elem.getContext('2d'));
    } catch (e) {
      // catch if older browser
      return false;
    }
  },

  // Get Canvas Print.  Return a string containing the canvas URI data.
  getCanvasPrint() {
    // create a canvas element
    let canvas = document.createElement('canvas');

    // define a context var that will be used for browsers with canvas support
    let ctx;

    // try/catch for older browsers that don't support the canvas element
    try {
      // attempt to give ctx a 2d canvas context value
      ctx = canvas.getContext('2d');
    } catch (e) {
      // return empty string if canvas element not supported
      return '';
    }

    // https://www.browserleaks.com/canvas#how-does-it-work
    // Text with lowercase/uppercase/punctuation symbols
    let txt = 'ClientJS,org <canvas> 1.0';
    ctx.textBaseline = 'top';
    // The most common type
    ctx.font = "14px 'Arial'";
    ctx.textBaseline = 'alphabetic';
    ctx.fillStyle = '#f60';
    ctx.fillRect(125, 1, 62, 20);
    // Some tricks for color mixing to increase the difference in rendering
    ctx.fillStyle = '#069';
    ctx.fillText(txt, 2, 15);
    ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
    ctx.fillText(txt, 4, 17);
    return canvas.toDataURL();
  },
};

export default ClientJS;
