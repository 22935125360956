import remove from 'lodash/remove';
import BlockModel from '../collections/block-model-collection.json';

export function blockFields(cleanItems = false) {
  const model = Object.keys(BlockModel);

  if (cleanItems) remove(model, (id) => id === 'items');

  return model.map((name) => {
    if (name === 'items' && !cleanItems) {
      const fields = blockFields(true);
      return { name: 'items', fields };
    }

    const fileObject = {
      name: 'file',
      fields: ['id', 'name', 'path', 'server'],
    };

    if (name === 'file') return { ...fileObject };
    if (name === 'image') return { ...fileObject, name };

    return name;
  });
}
