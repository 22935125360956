<template>

  <div
    id="pricing"
    class="block-pricing">

    <q-intersection
      once
      transition="scale">

      <h1 class="text-center">
        Тарифы <span class="text-primary">и цены</span>
      </h1>

    </q-intersection>

    <carousel
      ref="carousel"
      class="block-pricing__carousel"
      :paginationEnabled="false"
      :perPageCustom="[[200, 1], [1024, 3]]">

      <slide>

        <block-pricing-card
          title="Standard"
          :items="[
            'Доступ ко всем функциям',
            '1 страница',
            '3 визитки'
          ]"
          :price="100"/>

      </slide>

      <slide>

        <block-pricing-card
          title="Professional"
          :items="[
            'Доступ ко всем функциям',
            '10 страниц',
            '15 визиток'
          ]"
          :price="900"/>

      </slide>

      <slide>

        <block-pricing-card
          title="Business"
          :price="5000"
          :items="[
            'Доступ ко всем функциям',
            'Неограничено страниц',
            'Неограничено визиток',
            'Возможность скрыть надпись «сделано в BiCard» в нижней части страниц',
          ]"/>

      </slide>

    </carousel>

  </div>

</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import BlockPricingCard from '@/components/blocks/block-pricing-card';

export default {
  name: 'block-pricing',
  components: {
    Slide,
    Carousel,
    BlockPricingCard,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
  @import "src/styles/quasar.variables.scss";

  #pricing {

    .VueCarousel {
      margin: 60px auto 0;
      max-width: 1110px;

      &-wrapper {
        overflow: visible;
      }

      &-slide {
        padding: 15px;
      }
    }

    @media (max-width: $breakpoint-lg) {

      .VueCarousel {
        max-width: 450px;

        &-slide {
          padding: 10px;
        }
      }
    }

    @media (max-width: $breakpoint-sm-min) {

      .VueCarousel {
        max-width: 400px;

        &-slide {
          padding: 10px;
        }
      }
    }
  }

</style>
