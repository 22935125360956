import ClientJs from '@/plugins/client';
import Client from '../../plugins/graphql-client';

export async function EMIT(context, payload) {
  const clientJS = new ClientJs();

  const actionArgument = {
    name: 'createEvent',
    args: {
      ...payload,
      client: {
        type: clientJS.isMobile() ? 'mobile' : 'desktop',
        os: clientJS.getOS(),
        browser: clientJS.getBrowser(),
        browserVersion: clientJS.getBrowserVersion(),
        userAgent: clientJS.getUserAgent(),
        device: clientJS.getDevice(),
        resolution: clientJS.getCurrentResolution(),
        timeZone: clientJS.getTimeZone(),
        language: clientJS.getLanguage(),
        systemLanguage: clientJS.getSystemLanguage(),
      },
    },
  };

  const client = Client();
  const response = await client.mutation(actionArgument);

  return response || null;
}
