import pluralize from 'pluralize';

export default function fetchItem({
  name,
  client,
}) {
  return async (context, request = {}) => {
    const actionArgument = {
      name: pluralize(name, 1),
      args: { ...request.args || {} },
      fields: ['id'],
    };

    if (request.uid) actionArgument.uid = request.uid;
    if (request.fields) actionArgument.fields = request.fields;

    const item = await client.query(actionArgument, context);
    context.commit('SET_ITEM', item);
    return item;
  };
}
