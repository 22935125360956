var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block-pricing",attrs:{"id":"pricing"}},[_c('q-intersection',{attrs:{"once":"","transition":"scale"}},[_c('h1',{staticClass:"text-center"},[_vm._v(" Тарифы "),_c('span',{staticClass:"text-primary"},[_vm._v("и цены")])])]),_c('carousel',{ref:"carousel",staticClass:"block-pricing__carousel",attrs:{"paginationEnabled":false,"perPageCustom":[[200, 1], [1024, 3]]}},[_c('slide',[_c('block-pricing-card',{attrs:{"title":"Standard","items":[
          'Доступ ко всем функциям',
          '1 страница',
          '3 визитки'
        ],"price":100}})],1),_c('slide',[_c('block-pricing-card',{attrs:{"title":"Professional","items":[
          'Доступ ко всем функциям',
          '10 страниц',
          '15 визиток'
        ],"price":900}})],1),_c('slide',[_c('block-pricing-card',{attrs:{"title":"Business","price":5000,"items":[
          'Доступ ко всем функциям',
          'Неограничено страниц',
          'Неограничено визиток',
          'Возможность скрыть надпись «сделано в BiCard» в нижней части страниц' ]}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }