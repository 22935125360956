import Rollbar from 'vue-rollbar';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import createStore from './store';
import './quasar';

Vue.config.productionTip = false;

if (process.env.NODE_ENV === 'production') {
  Vue.use(Rollbar, {
    enabled: true,
    captureUncaught: true,
    environment: 'production',
    captureUnhandledRejections: true,
    accessToken: 'e68c0dea89634312b042c85a4b6eca2f',
  });
}

const store = createStore();

Vue.prototype.$scrollTo = (elementId) => {
  let el = elementId ? document.querySelector(elementId) : null;

  if (el) {
    window.scrollTo({
      top: el.getBoundingClientRect().top + window.scrollY - 90,
      behavior: 'smooth',
    });
  } else window.scrollTo(0, 0);
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
