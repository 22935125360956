import clone from 'lodash/clone';
import remove from 'lodash/remove';
import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';

export default function createMutations({
  initialState,
  extension = null,
} = {}) {
  // Initial state getter.
  const getInitialState = () => cloneDeep(initialState);

  // Create extension.
  let extensions = typeof extension === 'function'
    ? extension(initialState) || {}
    : extension;

  extensions = typeof extensions === 'object'
    ? extensions
    : {};

  return {
    INPUT: (state, item) => {
      state.tempItem = cloneDeep(item);
    },
    SET_ITEM: (state, item) => {
      state.item = cloneDeep(item);
      state.tempItem = cloneDeep(item);
    },
    RESET_ITEM: (state) => {
      state.item = getInitialState().item;
      state.tempItem = getInitialState().tempItem;
    },
    UPDATE_ITEM: (state, item) => {
      if (state.item.id === item.id) {
        state.item = item;
        state.tempItem = item;
      }

      state.items = state.items.map((itm) => (itm.id === item.id ? item : itm));
    },
    SYNC_TEMP: (state) => {
      state.item = cloneDeep(state.tempItem);
    },
    SET_LOADING: (state, value) => {
      state.loading = value || false;
    },
    RESET_LOADING: (state) => {
      state.loading = false;
    },

    APPEND_ITEM: (state, item) => {
      const items = cloneDeep(state.items);
      items.push(item);
      state.items = items;
    },
    SET_ITEMS: (state, items) => {
      state.items = clone(items);
    },
    RESET_ITEMS: (state) => {
      state.items = getInitialState().items;
    },
    UPDATE_ITEMS: (state, items) => {
      const stateItems = cloneDeep(state.items);

      forEach(items, (item) => {
        const index = stateItems.findIndex((itm) => item.id === itm.id);
        if (index >= 0) stateItems[index] = item;
      });

      state.items = stateItems;
    },
    REMOVE_ITEMS: (state, ids) => {
      if (state.item && ids.includes(state.item.id)) {
        state.item = getInitialState().item;
        state.tempItem = getInitialState().tempItem;
      }

      state.items = remove(state.items, (item) => {
        const itemId = ids.find((id) => id === item.id);
        return itemId === undefined;
      });
    },
    SET_ITEMS_SELECTABLE: (state, items) => {
      state.itemsSelectable = clone(items);
    },
    RESET_ITEMS_SELECTABLE: (state) => {
      state.itemsSelectable = getInitialState().itemsSelectable;
    },

    // Paginate-data mutations
    SET_PAGINATION: (state, paginate) => {
      state.pagination = {
        ...state.pagination,
        ...cloneDeep(paginate),
      };
    },
    RESET_PAGINATION: (state) => {
      state.pagination = getInitialState().pagination;
    },

    // Extension.
    ...extensions,
  };
}
