import * as getters from './getters';
import * as actions from './actions';
import * as mutations from './mutations';

import createModule from '../../plugins/store-factory';

const name = 'events';

export function createState() {
  return {
    // item: null,
    // items: [],
    // itemsSelectable: [],
    loading: false,
  };
}

export default function ({ client, onError, onSuccess }) {
  return {
    ...createModule({
      name,
      client,
      onError,
      onSuccess,
      getters,
      actions,
      mutations,
      state: createState(),
    }),
  };
}
