import { stringifyGqlPaginateQuery } from '@/plugins/graphql-client/utils';
import isString from 'lodash/isString';

export default function paginateQuery(client) {
  return async (fields) => {
    if (!fields) return null;

    const response = await client.post(
      '',
      { query: `{${stringifyGqlPaginateQuery(fields)}}` },
    ).catch((e) => console.warn(e.message));

    if (!response) return [];
    const { data, errors } = response.data;

    // console.log('# Response data');
    // console.log({ data, errors });

    // When response contain errors
    if (errors) {
      if (Array.isArray(errors)) {
        throw errors.map((e) => Error(e.message));
      } else {
        console.error(errors);
        throw Error('Invalid error');
      }
    }

    if (data) return data[isString(fields.name) ? fields.name : fields.name.many];

    throw new Error('Invalid response');
  };
}
