import {
  cut,
  getFileUrl,
} from './utils';

export default {
  methods: {
    cut,
    getFileUrl,
  },
};
