import Vue from 'vue';
import Vuex from 'vuex';
import Client from '../plugins/graphql-client';

import createEventsModule from './events';
import createPagesModule from './pages';

Vue.use(Vuex);

export default function CreateStore() {
  const client = new Client();

  const options = {
    client,
  };

  return new Vuex.Store({
    modules: {
      pages: {
        namespaced: true,
        ...createPagesModule(options),
      },
      events: {
        namespaced: true,
        ...createEventsModule(options),
      },
    },
  });
}
