import { stringifyGqlQuery } from '@/plugins/graphql-client/utils';

export default function mutation(client) {
  return async (fields) => {
    if (!fields) return null;

    let response;

    // Send File Query
    if (fields.file) {
      const bodyFormData = new FormData();
      bodyFormData.set('operations', JSON.stringify({
        query: `mutation ($file: Upload!) {${stringifyGqlQuery(fields)}}`,
        variables: { attachment: fields.file },
      }));
      bodyFormData.set('map', JSON.stringify({ file: ['variables.file'] }));
      bodyFormData.append('file', fields.file);

      response = await client.post(
        '',
        bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } },
      );

      // Default Query
    } else {
      response = await client.post(
        '',
        { query: `mutation {${stringifyGqlQuery(fields)}}` },
      );
    }

    if (!response) return null;
    const { data } = response;

    if (data && data.errors) {
      throw data.errors;
    }

    if (data && data.data) {
      return Array.isArray(fields)
        // When it multi-request - return data array
        ? data.data
        // When single request - return items by method name
        : data.data[fields.name];
    }

    throw new Error('Invalid response');
  };
}
