<template>

  <div
    v-if="value"
    class="page-viewer">

    <div
      :style="{
        height: headerBgHeight,
        background: value.design ? value.design.headerBg : null,
      }"
      class="page-viewer__header-bg"/>

    <div class="page-viewer__wrapper">

      <page-block
        :key="index"
        ref="blocks"
        :value="block"
        :disabled="disabled"
        @click="click(block)"
        :design="value.design"
        v-for="(block, index) in value.items"/>

    </div>

    <div class="page-viewer__copy">
      Сделано в <router-link :to="{ name: 'main' }">bicard</router-link>.
    </div>

  </div>

</template>

<script>
import moment from 'moment';
import BlockSocialsCollection from '../../collections/block-socials-collection.json';
import PageBlock from './page-block';

export default {
  name: 'page-viewer',
  components: { PageBlock },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tryIndex: 0,
      headerBgHeight: '',
    };
  },
  computed: {
    disabled() {
      if (!this.value) return true;
      const { owner } = this.value;

      if (!owner) return true;
      if (!owner.subscribeTo) return true;
      if (!owner.subscribeType) return true;
      if (owner.subscribeType === 'free') return true;
      if (moment().valueOf() > owner.subscribeTo) return true;

      return false;
    },
  },
  mounted() {
    this.setHeaderBgHeight();
  },
  methods: {
    setHeaderBgHeight() {
      if (!this.$refs.blocks || !this.$refs.blocks.length) {
        if (this.tryIndex < 9) setTimeout(this.setHeaderBgHeight, 100);
        else console.error('$refs.blocks is undefined');

        this.tryIndex++;
        return;
      }

      const block = this.$refs.blocks[1];

      const eh = block.$el.clientHeight;
      const et = block.$el.getBoundingClientRect().top;
      const pt = block.$el.parentElement.getBoundingClientRect().top;

      this.headerBgHeight = `${(et - pt) + (eh / 2)}px`;
    },
    click(block) {
      const link = document.createElement('a');
      document.body.appendChild(link);

      if (block.type === 'social') {
        const presets = BlockSocialsCollection;
        const preset = presets.find(({ value }) => value === block.preset);

        link.href = preset.link + block.action;
      } else link.href = block.action;

      link.target = '_blank';
      link.click();

      this.$store.dispatch('events/EMIT', {
        type: 'event',
        page: { id: this.value.id },
        data: {
          eventType: 'click',
          blockType: block.type,
          blockTitle: block.title,
          blockPreset: block.preset,
        },
      });
    },
  },
};
</script>

<style lang="scss">
  @import "src/styles/quasar.variables.scss";

 .page-viewer {
   margin: 0 auto;
   max-width: 360px;

   &__header-bg {
     top: 0;
     left: 0;
     width: 100%;
     position: absolute;
   }

   &__wrapper {
     padding: 12px 12px 0;
   }

   &__copy {
     line-height: 42px;
     text-align: center;

     a {
       color: $primary;
       font-weight: 600;
       text-decoration: none;
     }
   }
 }

</style>
