<template>

  <div
    :style="styles"
    class="layout-row">

    <div
      :style="wrapperStyles"
      class="layout-row__wrapper">

      <slot/>

    </div>

  </div>

</template>

<script>
export default {
  name: 'layout-row',
  components: {},
  props: {
    pt: {
      type: String,
    },
    pb: {
      type: String,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

    };
  },
  computed: {
    styles() {
      let paddingTop = this.pt;
      let paddingBottom = this.pb;

      if (this.$q.screen.lt.lg) {
        if (this.pt) paddingTop = '50px';
        if (this.pb) paddingBottom = '50px';
      }

      return {
        paddingTop,
        paddingBottom,
        backgroundColor: this.secondary ? '#F2F5FC' : undefined,
        height: this.fullHeight && this.$q.screen.gt.sm ? '100vh' : 'auto',
      };
    },
    wrapperStyles() {
      return {
        maxWidth: this.fullWidth ? '100%' : '1100px',
      };
    },
  },
};
</script>

<style lang="scss">
  @import "src/styles/quasar.variables.scss";

  .layout-row {
    padding: 0 35px;
    position: relative;

    &__wrapper {
      margin: 0 auto;
    }

    @media (max-width: $breakpoint-sm) {
      padding: 0 18px;
    }
  }

</style>
