<template>

  <q-card flat class="block-pricing-card">

    <div class="block-pricing-card__wrapper">

      <!-- Title and Bullets -->
      <div>

        <div class="block-pricing-card__title">

          {{ title }}

        </div>

        <ul class="block-pricing-card__items q-my-md">

          <li
            :key="index"
            v-for="(item, index) in items"
            class="block-pricing-card__items__item">
            {{ item }}
          </li>

        </ul>

      </div>

      <!-- Price and Button -->
      <div>

        <q-separator/>

        <div class="block-pricing-card__price">

          <span :style="{fontSize: price ? undefined : '32px'}">
            {{ price ? price + '₽' : 'договорная' }}
          </span>

          <span
            v-if="price"
            class="text-secondary">
           /месяц
        </span>

        </div>

        <q-btn
          flat
          type="a"
          padding="0 10px"
          label="Оформить подписку"
          class="button-primary full-width"
          href="https://my.bicard.pro/signup"/>

      </div>

    </div>

  </q-card>

</template>

<script>
export default {
  name: 'block-pricing-card',
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemsCount: {
      type: Number,
      default: 0,
    },
    price: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {

    };
  },
  methods: {},
};
</script>

<style lang="scss">
@import "src/styles/quasar.variables.scss";

  .block-pricing-card {
    height: 100%;
    font-size: 16px;
    border-radius: 30px !important;

    &:before {
      content: "";
      top: 36px;
      left: 40px;
      right: 40px;
      bottom: 0;
      z-index: -1;
      opacity: .20;
      filter: blur(50px);
      position: absolute;
      border-radius: 16px;
      background: linear-gradient(.57turn, #4388FF, #316BFF);
    }

    &:after {
      content: "";
      z-index: -1;
      top: -9px;
      left: 24px;
      right: 24px;
      height: 127px;
      position: absolute;
      border-radius: 30px;
      background: linear-gradient(.57turn, #4388FF, #316BFF);
    }

    &__wrapper {
      height: 100%;
      display: flex;
      overflow: hidden;
      padding: 50px 40px;
      position: relative;
      flex-direction: column;
      justify-content: space-between;
    }

    &__title {
      font-size: 28px;
      font-weight: 600;
    }

    &__items {
      padding-left: 20px;

      &__item {
        font-size: 16px;

        &::marker {
          color: #316BFF;
          font-size: 22px;
          line-height: 14px;
        }
      }
    }

    &__price {
      font-size: 36px;
      font-weight: 700;
      text-align: center;
      line-height: 100px;

      .text-secondary {
        font-size: 16px;
        font-weight: 400;
      }
    }

    @media (max-width: $breakpoint-xs-max) {
      font-size: 14px;

      &__wrapper {
        padding: 22px;
      }

      &__title div {
        font-size: 12px;
      }

      .q-btn__wrapper {
        font-size: 12px !important;
        padding: 8px 16px !important;
      }
    }
  }

</style>
