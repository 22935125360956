<template>

  <div
    id="properties"
    class="block-properties">

    <h1><span class="text-primary">Возможности</span> сервиса</h1>

    <div class="block-properties__wrapper">

      <div class="block-properties__row">

        <div class="block-properties__row__content pr">

          <h2>
            Создай страницу
            <span class="text-no-wrap">с <span class="text-primary">уникальным </span></span>
            <span class="text-primary">дизайном</span>
          </h2>

          <div class="text-secondary">
            Создай собственный дизайн или выбери из готовых шаблонов.<br/><br/>
            Удобный редактор позволит быстро разобраться и реализовать свои уникальные идеи
            оформления без программистов и дизайнеров. А коллекция из более чем 20 виджетов вам
            в этом поможет.
          </div>

        </div>

        <q-img
          contain
          class="block-properties__row__image"
          src="/assets/img/property-editor.png"/>

      </div>

      <div class="block-properties__row">

        <q-img
          contain
          class="block-properties__row__image"
          src="/assets/img/property-clients.png"/>

        <div class="block-properties__row__content pl">

          <h2>
            Преврати подписчиков <span class="text-primary text-no-wrap">в клиентов</span>
          </h2>

          <div class="text-secondary">
            Разместите ссылку в шапке своего профиля инстаграм.<br/>
            Укажите свои контакты или создайте форму для записи.<br/>
            Раздавайте чек-листы и прочие материалы своим подписчикам,
            вовлекая их в свою воронку!
          </div>

        </div>

      </div>

      <div class="block-properties__row">

        <div class="block-properties__row__content pr">

          <h2>
            Выбирай <span class="text-primary">удобный способ связи</span> с клиентами
          </h2>

          <div class="text-secondary">
            Ваши клиенты общаются в разных мессенджерах. Чтобы всем было удобно - предоставьте им
            выбор! Разместите ссылки на все свои мессенджеры и социальные сети, чтобы никого не
            упустить. Останется только успевать отвечать!
          </div>

        </div>

        <q-img
          contain
          class="block-properties__row__image"
          src="/assets/img/property-messages.png"/>

      </div>

      <div class="block-properties__row">

        <q-img
          contain
          class="block-properties__row__image"
          src="/assets/img/property-cards.png"/>

        <div class="block-properties__row__content pl">

          <h2>
            Создай <span class="text-primary">запоминающуюся</span> визитку
          </h2>

          <div class="text-secondary">
            Наши визитки не содержат контактов, а только ваше имя
            или название бренда. А на обратной стороне карточки расположен QR код,
            который можно считать камерой любого смартфона.<br/><br/>
            Это вызывает интерес у ваших клиентов, и позволяет
            держать все контакты на визитке актуальными. Даже если
            у вас сменится адрес, телефон или название сайта.
          </div>

        </div>

      </div>

      <div class="block-properties__row">

        <div class="block-properties__row__content pr">

          <h2>
            Собирай <span class="text-primary">статистику </span>
            <span class="text-no-wrap">и отслеживай </span>
            <span class="text-primary">конверсию</span>
          </h2>

          <div class="text-secondary">
            Созданные вами страницы фиксируют каждое посещении и любое действие ваших клиентов.
            Мы предоставляем всю эту информацию в виде графиков посещений и конверсий. <br><br>
            Анализируйте эти данные, чтобы понять - какие каналы связи и виджеты дают
            лучшую конверсию!
          </div>

        </div>

        <q-img
          contain
          class="block-properties__row__image"
          src="/assets/img/property-stat.png"/>

      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: 'block-properties',
};
</script>

<style lang="scss">
  @import "src/styles/quasar.variables.scss";

  .block-properties {

    @media (max-width: $breakpoint-lg) {
      padding-top: 50px;
    }

    h1 { text-align: center; }

    h2 { margin-bottom: 20px; }

    .q-intersection div { width: 100%; height: 100%; }

    .text-secondary {
      font-size: 16px;
      line-height: 28px;
    }

    &__wrapper {
      display: grid;
      grid-gap: 100px;
      padding-top: 60px;
    }

    &__row {
      display: grid;
      grid-gap: 30px;
      grid-template-columns: 1fr 1fr;

      .pl { padding-left: 30px; }
      .pr { padding-right: 30px; }

      @media (max-width: $breakpoint-lg) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;

        &:nth-child(odd) {

          .block-properties__row__image {
            height: 250px;
            grid-area: 1 / 1 / 1 / 2;
          }

          .block-properties__row__content {
            grid-area: 2 / 1 / 3 / 2;
          }
        }

        .pl { padding-left: 0; }
        .pr { padding-right: 0; }
      }

      &__image {
        width: 100%;
        height: 100%;
        position: relative;
      }
    }
  }

</style>
