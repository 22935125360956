/**
 * Get file Url.
 *
 * @param data
 * @return {string}
 */
export function getFileUrl(data) {
  const placeholder = 'http://fs.bicard.pro/img/placeholder.jpg';

  if (!data || !data.server || !data.path) return placeholder;

  return data.server + data.path;
}

/**
 * Blob to Data Url.
 *
 * @param blob
 * @return {Promise<*>}
 */
export async function blobToDataUrl(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => resolve(event.target.result);
    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(blob);
  });
}

/**
 * Simple file type.
 *
 * @param file
 * @return {string|undefined}
 */
export function simpleFileType(file) {
  if (!file) return undefined;

  const mime = file.mime || file.type || '';

  if (!mime) return undefined;

  if (mime.indexOf('image/') === 0) return 'image';

  if (mime.indexOf('video/') === 0) return 'video';

  return 'document';
}
