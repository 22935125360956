/**
 * Localize.
 *
 * @param object
 * @param locale
 * @return {string|*}
 */
export function localize(object, locale = 'ru') {
  if (object === 0) return object + '';

  if (!object && object !== 0) return '';

  if (typeof object === 'string') return object;

  if (locale in object) return object[locale];

  return '';
}

export function cut(string, max, ending = '\u2026') {
  if (!string) return string;

  string = string.trim();

  if (string.length <= max) return string;

  max = max || 40;
  ending = ending || '';

  return string.substr(0, max) + ending;
}
