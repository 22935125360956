import map from 'lodash/map';
import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';

export default function fetchItemsSelectable({
  name,
  model,
  client,
  onError,
  onSuccess,
}) {
  return async (context, request = {}) => {
    const actionArgument = {
      name,
      fields: ['id', 'name'],
    };

    if (request.uid) actionArgument.uid = request.uid;
    if (request.args) actionArgument.args = request.args;
    if (request.fields) actionArgument.fields = [...actionArgument.fields, ...request.fields];

    let result;
    let items = [];

    try {
      result = await client.query(actionArgument, context);
      if (result) {
        items = map(
          result,
          (item) => {
            const theModel = cloneDeep(model);
            return assign(theModel, item);
          },
        );
      }
    } catch (error) {
      onError(error);
    }

    context.commit('SET_ITEMS_SELECTABLE', items);
    onSuccess(items);
    return items;
  };
}
